import React from "react";

const DribbleSVG = ({ width, height }) => {
  return (
    <svg
      width="60px"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_7_643)">
        <path
          d="M43 83.0222C65.264 83.0222 83.3125 64.9737 83.3125 42.7097C83.3125 20.4457 65.264 2.39722 43 2.39722C20.736 2.39722 2.6875 20.4457 2.6875 42.7097C2.6875 64.9737 20.736 83.0222 43 83.0222Z"
          fill="#EC407A"
        />
        <path
          d="M43 0C19.2909 0 0 19.2909 0 43C0 66.7091 19.2909 86 43 86C66.7091 86 86 66.7091 86 43C86 19.2909 66.7091 0 43 0ZM80.6089 43.3225C71.6845 40.9305 62.3272 40.6398 53.2716 42.4733C52.0574 39.4448 50.7452 36.4565 49.3371 33.5131C57.4624 29.7092 64.9003 24.5835 71.3477 18.3449C77.3271 25.1666 80.6241 33.9286 80.625 43C80.625 43.1075 80.6089 43.2096 80.6089 43.3225ZM67.5476 14.5501C61.533 20.3837 54.5906 25.1772 47.0044 28.7348C42.9694 21.0236 38.2757 13.6755 32.9756 6.7725C36.2383 5.85471 39.6107 5.38458 43 5.375C52.0212 5.3736 60.739 8.63205 67.5476 14.5501ZM27.6651 8.67525C33.0972 15.5894 37.8916 22.9816 41.9895 30.7611C30.5919 35.1382 18.3045 36.6928 6.17588 35.2923C7.40776 29.4765 9.9964 24.034 13.7308 19.4085C17.4652 14.783 22.2397 11.1054 27.6651 8.67525ZM5.375 43C5.375 42.1937 5.44488 41.4036 5.49862 40.6135C8.68062 40.9897 11.8626 41.237 15.0339 41.237C25.1496 41.237 35.088 39.259 44.4029 35.6309C45.6929 38.3184 46.9076 41.0435 48.031 43.8062C34.5528 47.8402 22.9114 56.4639 15.1252 68.1819C8.85605 61.2929 5.37968 52.3145 5.375 43ZM19.0544 71.9981C26.2035 60.7732 37.1897 52.5301 49.966 48.805C53.379 58.1973 55.8142 67.9165 57.233 77.8085C50.9418 80.3998 44.0635 81.2281 37.3369 80.2043C30.6104 79.1806 24.2899 76.3437 19.0544 71.9981ZM62.2748 75.25C60.8117 65.8179 58.4576 56.5457 55.2442 47.558C63.5222 46.0187 72.0444 46.4198 80.1413 48.7298C79.2923 54.1907 77.2515 59.3977 74.1637 63.9811C71.0759 68.5645 67.0168 72.4119 62.2748 75.25Z"
          fill="#C2185B"
        />
      </g>
    </svg>
  );
};

export default DribbleSVG;
