import React, { useState } from "react";
import "./projects.scss";
import figmaWidgetsData from "../data/figmaWidgetData.json";
import sandBoxWidgetsData from "../data/sandboxWidgetData.json";
import useWidth from "../hooks/useWidth";

const Projects = ({ showIframe, onIframeChange }) => {
  const [hoveredImage, setHoveredImage] = useState(null);
  const [iframeSrc, setIframeSrc] = useState("");
  const [filter, setFilter] = useState("All");
  const [figmaWidgets, setFigmaWidgets] = useState(false);
  const [isHovered, setIsHovered] = useState(true);
  const [figmaFolio, setFigmaFolio] = useState(false);
  const [isIframeVisible, setIsIframeVisible] = useState(showIframe);
  const screenWidth = useWidth();

  const handleWidgetMouseEnter = (image) => {
    setHoveredImage(image);
    setIsHovered(true);
  };

  const handleWidgetMouseLeave = () => {
    setHoveredImage(null);
    setIsHovered(false);
  };

  const handleFrame = (link) => {
    setIframeSrc(link);
    setIsIframeVisible(true);
    onIframeChange(true); // Notify parent
  };

  const handleCloseIframe = () => {
    setIsIframeVisible(false);
    onIframeChange(false);
    console.log("hello")
  };

  const handleFigmaFrame = () => {
    setIframeSrc(
      "https://embed.figma.com/proto/NS22uNFfpn9WgCBNwqcEx9/AS-Portfolio?page-id=523%3A8490&node-id=2028-10236&node-type=frame&viewport=-5294%2C-5406%2C0.12&scaling=min-zoom&content-scaling=fixed&starting-point-node-id=2028%3A10236&embed-host=share"
    );
    setIsIframeVisible(true);
    onIframeChange(true);
  };

  const handleNewTab = (link) => {
    window.open(link, "_blank", "noopener,noreferrer");
  };

  const backgroundurls = `url(img/widgetImages/${hoveredImage})`;

  return (
    <div className="project-dashboard">
      {isIframeVisible && (
        <div className="iframeCinema">
          <div className="iframeWrapper">
            <p
              className="exitButton"
              onClick={handleCloseIframe}
              onTouchStart={(e) => {
                e.stopPropagation();
                handleCloseIframe();
              }}
              onTouchEnd={(e) => e.stopPropagation()}
            >
              X
            </p>
            {!isIframeVisible ? null : <p className="loading-project">loading...</p>}
            <iframe
              title="Embedded Content"
              src={iframeSrc}
              width="100%"
              height="100%"
              allowFullScreen
              loading="lazy"
            />
          </div>
        </div>
      )}
      <div className="side-bar">
        <div className="side-bar-items-wrapper">
          <div className="side-bar-items">
            <div
              className={`icon-wrapper ${figmaWidgets && !figmaFolio
                ? "active"
                : !figmaFolio && !figmaWidgets
                  ? ""
                  : null
                }`}
            >
              <div
                className="code-icon"
                onClick={() => {
                  setFigmaWidgets(true);
                  setFigmaFolio(false);
                }}
              >
                Coding practice
              </div>
            </div>
            <div
              className={`icon-wrapper ${figmaWidgets && !figmaFolio
                ? ""
                : figmaFolio && figmaWidgets
                  ? ""
                  : "active"
                }`}
            >
              <div
                className="code-icon"
                onClick={() => {
                  setFigmaWidgets(false);
                  setFigmaFolio(false);
                }}
              >
                Figma projects
              </div>
            </div>
            <div className={`icon-wrapper ${!figmaFolio ? "" : "active"}`}>
              <div
                className="code-icon"
                onClick={() => {
                  setFigmaFolio(true);
                  handleFigmaFrame();
                }}
              >
                Figma portfolio
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="widget-screen-container"
        style={{
          backgroundImage: hoveredImage ? backgroundurls : backgroundurls,
          transition: "background-image 1s ease-in-out",
        }}
      >
        <div className="filterWrapper">
          {screenWidth > 768 ? (
            <div
              className={`allCards ${filter === "All" ? "active" : ""}`}
              onClick={() => setFilter("All")}
            >
              All
            </div>
          ) : (
            <div
              className={`allCards ${filter === "All" && !figmaWidgets
                ? "mobileActive"
                : "mobileInactive"
                }`}
              onClick={() => {
                setFilter("All");
                setFigmaWidgets(false);
              }}
            >
              Designs
            </div>
          )}

          {screenWidth > 768 ? (
            <div
              className={`filterComm ${filter === "Commercial" ? "active" : ""
                }`}
              onClick={() => setFilter("Commercial")}
            >
              Professional
            </div>
          ) : (
            <div
              className={`filterComm ${filter === "All" && figmaWidgets
                ? "mobileActive"
                : "mobileInactive"
                }`}
              onClick={() => {
                setFilter("All");
                setFigmaWidgets(true);
              }}
            >
              Coding
            </div>
          )}
          {screenWidth > 768 ? (
            <div
              className={`filterConcept ${filter === "Concepts" ? "active" : ""}`}
              onClick={() => setFilter("Concepts")}
            >
              Spec work
            </div>
          ) : (
            !isIframeVisible && (
              <div
                className="filterFolioCard"
                onClick={() => {
                  setFigmaFolio(true);
                  handleFigmaFrame();
                }}
              >
                Highlights
              </div>
            )
          )}
        </div>

        <div className="widget-screen-opacity" />
        <div className="widget-screen">
          {figmaWidgets
            ? sandBoxWidgetsData.map((widget, index) => {
              if (
                (filter === "Commercial" && widget.type === "commercial") ||
                (filter === "Concepts" && widget.type === "concept") ||
                filter === "All"
              ) {
                return (
                  <div
                    className="project-widget-sandBox"
                    onMouseEnter={() => handleWidgetMouseEnter(widget.image)}
                    onTouchStart={() => handleWidgetMouseEnter(widget.image)}
                    onMouseLeave={() => handleWidgetMouseLeave(null)}
                    key={index}
                    style={{
                      transform:
                        hoveredImage && hoveredImage !== widget.image
                          ? "none"
                          : "scale(1.3)",
                      transformOrigin: "center",
                      transition: "transform 0.3s ease-in-out",
                    }}
                  >
                    <div className="widget-body">
                      {hoveredImage === widget.image ? null : (
                        <div className="widget-left">
                          <img
                            className="widget-image"
                            src={require(`../assets/widgetImages/${widget.image}`)}
                            alt="project landing"
                          />
                        </div>
                      )}
                      <div
                        className={`widget-right ${isHovered && hoveredImage === widget.image
                          ? "fullWidgetRight"
                          : ""
                          }`}
                      >
                        <div
                          className={`widget-heading ${isHovered && hoveredImage === widget.image
                            ? "fullWidgetHeading"
                            : ""
                            }`}
                        >
                          {widget.heading}
                        </div>
                        {isHovered && hoveredImage === widget.image ? (
                          <div
                            className={`widget-description ${isHovered && hoveredImage === widget.image
                              ? "fullWidgetSubHeading"
                              : ""
                              }`}
                          >
                            {widget.content}
                          </div>
                        ) : null}
                        {isHovered && hoveredImage === widget.image ? (
                          <>
                            <div
                              className="widget-open-iframe"
                              onClick={() => handleFrame(widget.link)}
                            >
                              Open in-app
                            </div>
                            <div
                              className="widget-open-ext"
                              onClick={() => handleNewTab(widget.link)}
                            >
                              Open new tab
                            </div>
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>
                );
              }
              return null; // Don't render this widget
            })
            : figmaWidgetsData.map((widget, index) => {
              if (
                (filter === "Commercial" && widget.type === "commercial") ||
                (filter === "Concepts" && widget.type === "concept") ||
                filter === "All"
              ) {
                return (
                  <div
                    className="project-widget"
                    onMouseEnter={() => handleWidgetMouseEnter(widget.image)}
                    onTouchStart={() => handleWidgetMouseEnter(widget.image)}
                    onMouseLeave={() => handleWidgetMouseLeave(null)}
                    key={index}
                    style={{
                      transform:
                        hoveredImage && hoveredImage !== widget.image
                          ? "none"
                          : "scale(1.3)",
                      transformOrigin: "center",
                      transition: "transform 0.3s ease-in-out",
                    }}
                  >
                    <div className="widget-body">
                      {hoveredImage === widget.image ? null : (
                        <div className="widget-left">
                          <img
                            className="widget-image"
                            src={require(`../assets/widgetImages/${widget.image}`)}
                            alt=""
                          />
                        </div>
                      )}
                      <div
                        className={`widget-right ${isHovered && hoveredImage === widget.image
                          ? "fullWidgetRight"
                          : ""
                          }`}
                      >
                        <div
                          className={`widget-heading ${isHovered && hoveredImage === widget.image
                            ? "fullWidgetHeading"
                            : ""
                            }`}
                        >
                          {widget.heading}
                        </div>
                        {isHovered && hoveredImage === widget.image ? (
                          <div
                            className={`widget-description ${isHovered && hoveredImage === widget.image
                              ? "fullWidgetSubHeading"
                              : ""
                              }`}
                          >
                            {widget.content}
                          </div>
                        ) : null}
                        {isHovered && hoveredImage === widget.image ? (
                          <>
                            <div
                              className="widget-open-iframe"
                              onClick={() => {
                                screenWidth > 768
                                  ? handleFrame(widget.link)
                                  : handleFrame(widget.mobileLink);
                              }}
                            >
                              Open in-app
                            </div>
                            <div
                              className="widget-open-ext"
                              onClick={() => {
                                screenWidth > 768
                                  ? handleNewTab(widget.standardLink)
                                  : handleNewTab(widget.standardMobileLink);
                              }}
                            >
                              Open new tab
                            </div>
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>
                );
              }
              return null; // Don't render this widget
            })}
        </div>
      </div>
    </div>
  );
};

export default Projects;
