import React from "react";

const LinkedSVG = ({ width, height }) => (
  <svg
    width="60px"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M42.9999 0C19.2516 0 0 19.2516 0 43C0 66.7484 19.2516 86 42.9999 86C66.7483 86 86 66.7484 86 43C85.9998 19.2516 66.7479 0 42.9999 0Z"
      fill="url(#paint0_linear_7_642)"
    />
    <path d="M31.5275 63.7885H22.0864V33.2871H31.5275V63.7885Z" fill="white" />
    <path
      d="M26.7619 29.2927C23.6786 29.2927 21.179 26.7727 21.179 23.6646C21.179 20.5562 23.6789 18.0364 26.7619 18.0364C29.8449 18.0364 32.3445 20.5562 32.3445 23.6646C32.3447 26.7729 29.8451 29.2927 26.7619 29.2927Z"
      fill="white"
    />
    <path
      d="M66.9078 63.7885H57.5125V47.7778C57.5125 43.3865 55.8445 40.9353 52.3722 40.9353C48.5932 40.9353 46.619 43.4882 46.619 47.7778V63.7885H37.5639V33.2871H46.619V37.3951C46.619 37.3951 49.3428 32.3567 55.8103 32.3567C62.2781 32.3567 66.908 36.3058 66.908 44.4754L66.9078 63.7885Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_7_642"
        x1="12.5944"
        y1="12.5944"
        x2="73.4056"
        y2="73.4055"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2489BE" />
        <stop offset="1" stopColor="#0575B3" />
      </linearGradient>
    </defs>
  </svg>
);

export default LinkedSVG;
