import React, { useState, useMemo, useRef } from "react";
import TinderCard from "react-tinder-card";
import "./card.scss";

const db = [
  {
    name: "Chimeable",
    desc: "Optimised the experience of generating product reviews",
    skillone: "Competitive analysis",
    skilltwo: "User ineterviews",
    skillthree: "Visual design",
    url: "./img/chimeable.png",
  },
  {
    name: "AMS Laboratory Technologies",
    desc: "Streamlined the experience of ordering lab equipment",
    skillone: "Market research",
    skilltwo: "Usability testing",
    skillthree: "Visual design",
    url: "./img/amslt.png",
  },
  {
    name: "Story Cube",
    desc: "Simplified course discovery and purchases",
    skillone: "Competitive analysis",
    skilltwo: "Wireframing",
    skillthree: "Visual design",
    url: "./img/storycube.png",
  },
  {
    name: "UK Mathematics Trust",
    desc: "Elevated the process of discovering math competitions",
    skillone: "Market analysis",
    skilltwo: "Usability testing",
    skillthree: "Visual design",
    url: "./img/ukmt.png",
  },
];

function Advanced() {
  let dbindex = db.length - 1;
  const [currentIndex, setCurrentIndex] = useState(db.length - 1);
  const [lastDirection, setLastDirection] = useState();
  // used for outOfFrame closure
  const currentIndexRef = useRef(currentIndex);

  const childRefs = useMemo(
    () =>
      Array(db.length)
        .fill(0)
        .map((i) => React.createRef()),
    []
  );

  const updateCurrentIndex = (val) => {
    setCurrentIndex(val);
    currentIndexRef.current = val;
    console.log(val);
  };

  // const updateCurrentIndex = (val) => {
  //   let newIndex = val;
  //   // Reset to the beginning of the array when reaching the end
  //   if (newIndex < 0) {
  //     newIndex = db.length - 1;
  //   } else if (newIndex >= db.length) {
  //     newIndex = 0;
  //   }

  //   setCurrentIndex(newIndex);
  //   currentIndexRef.current = newIndex;
  // };

  const canGoBack = currentIndex < db.length - 1;

  const canSwipe = currentIndex >= 0;

  // set last direction and decrease current index
  const swiped = (direction, nameToDelete, index) => {
    setLastDirection(direction);
    if (index > -1) {
      updateCurrentIndex(index - 1);
    } else if (index === -1) {
      updateCurrentIndex(3);
    }
  };

  const outOfFrame = (name, idx) => {
    console.log(`${name} (${idx}) left the screen!`, currentIndexRef.current);
    // handle the case in which go back is pressed before card goes outOfFrame
    currentIndexRef.current >= idx && childRefs[idx].current.restoreCard();
    // TODO: when quickly swipe and restore multiple times the same card,
    // it happens multiple outOfFrame events are queued and the card disappear
    // during latest swipes. Only the last outOfFrame event should be considered valid
  };

  const swipe = async (dir) => {
    if (canSwipe && currentIndex < db.length) {
      await childRefs[currentIndex].current.swipe(dir); // Swipe the card!
    }
  };

  // increase current index and show card
  const goBack = async () => {
    if (!canGoBack) return;
    const newIndex = currentIndex + 1;
    updateCurrentIndex(newIndex);
    await childRefs[newIndex].current.restoreCard();
  };

  const reset = () => {
    updateCurrentIndex(db.length - 1);
    setLastDirection(null);
    childRefs.forEach((ref) => {
      if (ref.current) {
        ref.current.restoreCard();
      }
    });
  };

  const cardComponents = [];

  for (let index = 0; index < db.length; index++) {
    const character = db[index];

    cardComponents.push(
      <TinderCard
        ref={childRefs[index]}
        className="swipe"
        key={character.name}
        onSwipe={(dir) => swiped(dir, character.name, index)}
        onCardLeftScreen={() => outOfFrame(character.name, index)}
      >
        <div
          style={{ backgroundImage: "url(" + character.url + ")" }}
          className="card"
        >
          <div className="heading-wrap">
            <h3>{character.name}</h3>
            <p>{character.desc}</p>
            <p>
              <span>{character.skillone}</span> |
              <span>{character.skilltwo}</span> |
              <span>{character.skillthree}</span>
            </p>
          </div>
          <div className="buttons">
            {!canGoBack ? null : (
              <button
                style={{ backgroundColor: !canGoBack && "#c3c4d3" }}
                onClick={() => goBack()}
                onTouchStart={() => goBack()}
              >
                Back
              </button>
            )}
            <button
              style={{ backgroundColor: !canSwipe && "#c3c4d3" }}
              onClick={() => swipe("right")}
            >
              Next
            </button>
          </div>
          <div className="buttons-mobile">
           <button>swipe</button>
          </div>
        </div>
      </TinderCard>
    );
  }

  return (
    <div className="cardContainer">
      {currentIndex === -1 ? (
        <div className="buttonR" onClick={() => reset()}>
          Reset
        </div>
      ) : (
        cardComponents
      )}
    </div>
  );
}

export default Advanced;
