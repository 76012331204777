import React from "react";

const FigmaSVGFront = ({ width, height }) => {
  return (
    <svg
      width="51.6px"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_29_6)">
        <path
          d="M85.3546 0.0961914H14.6454C6.6101 0.0961914 0.0961914 6.6101 0.0961914 14.6454V85.3546C0.0961914 93.39 6.6101 99.9039 14.6454 99.9039H85.3546C93.39 99.9039 99.9039 93.39 99.9039 85.3546V14.6454C99.9039 6.6101 93.39 0.0961914 85.3546 0.0961914Z"
          fill="#525967"
        />
        <path
          d="M85.3546 4.46096C88.0557 4.46096 90.6462 5.53396 92.5561 7.44392C94.4661 9.35387 95.5391 11.9443 95.5391 14.6454V85.3546C95.5391 88.0557 94.4661 90.6462 92.5561 92.5561C90.6462 94.4661 88.0557 95.5391 85.3546 95.5391H14.6454C11.9443 95.5391 9.35387 94.4661 7.44392 92.5561C5.53396 90.6462 4.46096 88.0557 4.46096 85.3546V14.6454C4.46096 11.9443 5.53396 9.35387 7.44392 7.44392C9.35387 5.53396 11.9443 4.46096 14.6454 4.46096H85.3546ZM85.3546 0.0961914H14.6454C10.7867 0.0961914 7.08607 1.62905 4.35756 4.35756C1.62905 7.08607 0.0961914 10.7867 0.0961914 14.6454V85.3546C0.0961914 89.2133 1.62905 92.914 4.35756 95.6425C7.08607 98.371 10.7867 99.9039 14.6454 99.9039H85.3546C89.2133 99.9039 92.914 98.371 95.6425 95.6425C98.371 92.914 99.9039 89.2133 99.9039 85.3546V14.6454C99.9039 10.7867 98.371 7.08607 95.6425 4.35756C92.914 1.62905 89.2133 0.0961914 85.3546 0.0961914Z"
          fill="#454B57"
        />
        <path
          d="M37.5604 87.3189C39.194 87.3189 40.8116 86.9971 42.3209 86.372C43.8301 85.7468 45.2014 84.8305 46.3565 83.6754C47.5117 82.5203 48.428 81.1489 49.0531 79.6397C49.6783 78.1305 50 76.5129 50 74.8793V62.4397H37.5604C35.9268 62.4397 34.3093 62.7615 32.8 63.3866C31.2908 64.0118 29.9194 64.928 28.7643 66.0832C27.6092 67.2383 26.6929 68.6096 26.0678 70.1189C25.4426 71.6281 25.1208 73.2457 25.1208 74.8793C25.1208 76.5129 25.4426 78.1305 26.0678 79.6397C26.6929 81.1489 27.6092 82.5203 28.7643 83.6754C29.9194 84.8305 31.2908 85.7468 32.8 86.372C34.3093 86.9971 35.9268 87.3189 37.5604 87.3189Z"
          fill="#0ACF83"
        />
        <path
          d="M25.1208 50.0001C25.1208 46.7009 26.4314 43.5369 28.7643 41.204C31.0972 38.8711 34.2613 37.5605 37.5604 37.5605H50V62.4397H37.5604C34.2613 62.4397 31.0972 61.1291 28.7643 58.7962C26.4314 56.4634 25.1208 53.2993 25.1208 50.0001Z"
          fill="#A259FF"
        />
        <path
          d="M25.1208 25.1207C25.1208 21.8216 26.4314 18.6575 28.7643 16.3246C31.0972 13.9917 34.2613 12.6812 37.5604 12.6812H50V37.5603H37.5604C34.2613 37.5603 31.0972 36.2497 28.7643 33.9169C26.4314 31.584 25.1208 28.4199 25.1208 25.1207Z"
          fill="#F24E1E"
        />
        <path
          d="M50 12.7539H62.4396C65.7388 12.7539 68.9028 14.0645 71.2357 16.3974C73.5686 18.7303 74.8792 21.8943 74.8792 25.1935C74.8792 28.4927 73.5686 31.6567 71.2357 33.9896C68.9028 36.3225 65.7388 37.6331 62.4396 37.6331H50V12.7539Z"
          fill="#FF7262"
        />
        <path
          d="M74.8792 50.0001C74.8792 52.4605 74.1496 54.8655 72.7827 56.9112C71.4159 58.9569 69.4731 60.5513 67.2 61.4928C64.927 62.4343 62.4258 62.6807 60.0127 62.2007C57.5997 61.7207 55.3832 60.536 53.6435 58.7962C51.9038 57.0565 50.719 54.84 50.239 52.427C49.759 50.0139 50.0054 47.5127 50.9469 45.2397C51.8884 42.9667 53.4828 41.0239 55.5285 39.657C57.5742 38.2901 59.9793 37.5605 62.4396 37.5605C65.7388 37.5605 68.9028 38.8711 71.2357 41.204C73.5686 43.5369 74.8792 46.7009 74.8792 50.0001Z"
          fill="#1ABCFE"
        />
      </g>
      <defs>
        <clipPath id="clip0_29_6">
          <rect width="100" height="100" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FigmaSVGFront;
